module.exports = {
    run_console: {
        ja: "実行",
        en: "Run",
        es: "Ejecutar"
    },
    clear_console: {
        ja: "ログをクリア",
        en: "Clear",
        es: "Limpiar"
    },
    exit_console: {
        ja: "終了",
        en: "Exit",
        es: "Salir"
    }
};
